<template>
  <section class="news">
    <div class="news__container container">
      <HeaderSection title="TIN TỨC" :url-section="URLS.NEWS" text-url="Xem Tất Cả" :is-hide-light-arrow="false" />
      <div class="news__content">
        <div v-for="(item, index) in temp" :key="index" class="news-section-item">
          <NewsSectionItem :item="item.posts[0]" :title="item.title" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import HeaderSection from '@/components/common/header-section.vue'
import NewsSectionItem from '@/components/desktop/pages/home/news/news-section-item.vue'
import { useCategories } from '~/composables/news/useCategories'
import { URLS } from '~/config/page-url'

const { getCategories, categoryList } = useCategories()

// TODO: Remove this temp data when integrate with API
const temp = ref([
  {
    title: 'BẮN CÁ ONLINE',
    posts: [
      {
        alias: 'ky-thuat-ban-ca-de-co-the-chien-thang-giai-thuong-cao-nhat',
        title: 'Kỹ thuật bắn cá để chiến thắng',
        desc: 'Bắn cá hình thành theo thời gian chơi...',
        created_time: '08-05-2024',
        thumbnail: 'assets/images/components/desktop/pages/home/news/ban-ca.webp'
      }
    ]
  },
  {
    title: 'NỔ HŨ - SLOTS',
    posts: [
      {
        alias: 'nhung-tinh-nang-va-thuat-ngu-co-trong-slot-game-online',
        title: 'Tính năng và Thuật ngữ Slots',
        desc: 'Slot game hay còn gọi là nổ hũ...',
        created_time: '08-05-2024',
        thumbnail: 'assets/images/components/desktop/pages/home/news/slots.webp'
      }
    ]
  },
  {
    title: 'LIVE CASINO',
    posts: [
      {
        alias: 'mach-ban-dia-chi-choi-live-casino-uy-tin-tot-nhat-2021',
        title: 'Địa chỉ chơi Live casino uy tín',
        desc: 'Bắn cá hình thành theo thời gian chơi...',
        created_time: '08-05-2024',
        thumbnail: 'assets/images/components/desktop/pages/home/news/casino.webp'
      }
    ]
  }
])

onMounted(async () => {
  await getCategories()
})
</script>
<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/news/news.scss';
</style>
