export const TYPE_NAVIGATE_LINK = 'navigate-link'

export const PLAN_TYPE_SUKIEN_THUONG18 = 3
export const PLAN_TYPE_SUKIEN_HOANTRA15 = 1
export const PLAN_TYPE_SUKIEN_THUONG118 = 2
export const PLAN_TYPE_SUKIEN_THUONG150 = 5
export const PLAN_TYPE_SUKIEN_THUONG100 = 100

export const promotionItems = [
  {
    id: 'event-200',
    banner: '/assets/images/common/khuyen-mai/freespin200.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/freespin200_MB.webp',
    title: 'Tặng 200 vòng quay miễn phí',
    description:
      'Nhận lượt săn hũ miễn phí mỗi ngày bằng cách nạp tiền và tham gia cá cược tại nhà cái PS-18NEW',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      popupQuery: 'event-freespin200',
      endpoint: '/khuyen-mai/tang-200-vong-quay-mien-phi',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/vong-quay.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/vong-quay-mb.webp'
    }
  },
  {
    id: 'athena88',
    banner: '/assets/images/common/khuyen-mai/athena88.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/athena88_MB.webp',
    title: 'Săn vé cược may mắn',
    description:
      'Chương trình áp dụng cho khách hàng tham gia cược Thể thao C-Sports, K-sports, E-Sports và Thể thao ảo Saba tại nhà cái PS-18NEW.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      popupQuery: 'event-athena88',
      endpoint: '/khuyen-mai/san-ve-cuoc-may-man',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/san-ve.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/san-ve-mb.webp'
    }
  },
  {
    id: 'tet',
    banner: '/assets/images/common/khuyen-mai/thumb-tet.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/thumb-tet-mb.webp',
    title: 'SĂN NGŨ LINH RINH QUÀ TẾT',
    description:
      'Chương trình Săn ngũ linh - Rinh quà tết, diễn ra từ 30/01/2022-16/02/2022. Nhanh tay đăng ký tham gia và nhận hàng ngàn giải thưởng tiền mặt miễn phí cùng nhà cái PS-18NEW.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      type: TYPE_NAVIGATE_LINK,
      endpoint: '/khuyen-mai/event-tet',
      isRequiredLogin: false
    }
  },
  {
    id: 'k-sport',
    banner: '/assets/images/common/khuyen-mai/sukienksport.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/sukienksport_MB.webp',
    title: 'BÙNG NỔ THỂ THAO, THƯỞNG CAO TỚI 8.8% TRÊN K-SPORTS',
    description:
      'Chơi hết mình với thể thao tại nhà cái <span class="strong">PS-18NEW</span>, và nhận thưởng từ chương trình thưởng lớn 8.8% mỗi tuần khi chơi thể thao K-SPORTS.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/bung-no-the-thao-thuong-cao-toi-8-8-tren-k-sports',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/khuyen-mai/popup/sukienksport.webp',
      popupContentName: 'ContentKSport',
      enpointBannerMoible:
        '/assets/images/common/khuyen-mai/popup/sukienksport_MB.webp'
    }
  },
  {
    id: '12-12',
    banner: '/assets/images/common/khuyen-mai/sukien12.12.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/sukien12.12_MB.webp',
    title: '12.12 SĂN THƯỞNG LÊN TỚI 1,110,000 VND',
    description:
      'Sự kiện 12.12 Săn Thưởng Lên Tới 1,110,000 VND áp dụng cho khách hàng tham gia cược Thể thao, Live Casino, Game Bài và Quay Số, tham gia ngay tại <span class="strong">PS-18NEW</span>.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/san-thuong-12-12',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/khuyen-mai/popup/sukien12.12.webp',
      popupContentName: 'Content12Thang12',
      enpointBannerMoible:
        '/assets/images/common/khuyen-mai/popup/sukien12.12_MB.webp'
    }
  },
  {
    id: 'noel',
    banner: '/assets/images/common/khuyen-mai/noel.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/noel_MB.webp',
    title: 'Rung cây nhận quà',
    description:
      'Chương trình Rung cây nhận quà - Giáng sinh cực đã, diễn ra từ 20/12/2021 - 04/01/2022. Nhanh tay đăng ký tham gia và nhận hàng ngàn giải thưởng tiền mặt miễn phí cùng nhà cái <span class="strong">PS-18NEW</span>.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Tham Gia Ngay',
      endpoint: '/khuyen-mai/event-noel',
      isRequiredLogin: false
    }
  },
  {
    id: 'intro-friend',
    banner: '/assets/images/common/khuyen-mai/chiasebanbe.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/chiasebanbe_MB.webp',
    title: 'Giới thiệu nhiều - nhận tiền triệu lên đến 15,000,000 VND/lượt',
    description:
      'Chương trình không giới hạn số lượng người được giới thiệu và số tiền thưởng.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/event-chiasebanbe',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/friend.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/frienmb.webp'
    }
  },
  {
    id: 'return-158',
    banner: '/assets/images/common/khuyen-mai/hoantra1.58.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/hoantra1.58_MB.webp',
    title: 'Siêu Hoàn trả 1.25% khi chơi keno, duy nhất trên thị trường',
    description:
      'Hoàn trả đã lớn nay còn lớn hơn. Duy nhất trên thị trường, Keno hoàn trả 1.25% Keno Đại Phát 88 và 1% Keno Thần Tài 79 chỉ có tại <span class="strong">PS-18NEW</span>.',
    isNew: true,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/sieu-hoan-tra-1-25-khi-choi-keno-duy-nhat-tren-thi-truong',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/sieu-hoantra-125.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/sieu-hoantra-125-mb.webp'
    }
  },
  {
    id: '100k',
    packageId: PLAN_TYPE_SUKIEN_THUONG100,
    banner: '/assets/images/common/khuyen-mai/khuyenmai100k.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/khuyenmai100k_MB.webp',
    title: 'TẶNG 100K CHO TÀI KHOẢN XÁC MINH THÔNG TIN CHÍNH CHỦ',
    description:
      'Hoàn thành các nhiệm vụ đơn giản tại <span class="strong">PS-18NEW</span> để nhận ngay 100K trải nghiệm các trò chơi hấp dẫn.',
    isNew: false,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Tham Gia Ngay',
      endpoint: '/khuyen-mai/tang-100k-cho-tai-khoan-xac-minh-thong-tin-chinh-chu',
      isRequiredLogin: true
    }
  },
  {
    id: 'thuong-18',
    packageId: PLAN_TYPE_SUKIEN_THUONG18,
    banner: '/assets/images/common/khuyen-mai/khuyenmai18.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/khuyenmai18_MB.webp',
    title: 'Thưởng 18% cho lần nạp thứ 2',
    description:
      'Thành viên <span class="strong">PS-18NEW</span> tại lần gửi tiền thứ 2 có thể nhận thêm 18% tiền thưởng với số tiền thưởng tối đa có thể nhận là 1,800,000 VND.',
    isNew: false,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/thuong-18-cho-lan-nap-thu-2',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/thuong-18.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/thuong-18-mb.webp',
      endpointTitleMobile: 'Thưởng 18%'
    },
    contentDetails: `
    1. Thành viên <span class="strong">PS-18NEW</span> tại lần gửi tiền thứ 2 có thể nhận thêm 18% tiền thưởng với số tiền thưởng tối đa có thể nhận là 1,800,000 VND.</br>
    2. Trước khi rút tiền, tổng tiền gửi và tiền thưởng cần trải qua 10 vòng cược.<div class="line-break" />Ví dụ: Bạn nạp lần kế tiếp số tiền 1,000,000 VND, bạn sẽ nhận thưởng 18% tương đương 180,000 VND. Tổng số tiền này cần trải qua 10 vòng cược, tương ứng tổng tiền cược của bạn sẽ là: (1,000,000 + 180,000) x 10 = 11,800,000 VND.<br/>
    3. Khuyến mãi 18% áp dụng theo doanh thu cược, được tính như sau:<div class="line-break" />Thể thao; Thể thao ảo doanh thu được tính = 100% doanh thu cược.<div class="line-break" />Quay số doanh thu được tính = 40% doanh thu cược<div class="line-break" />Keno; Number Game và Nổ hũ (Slots Techplay) doanh thu được tính = 25% doanh thu cược<div class="line-break" /><b>(Không áp dụng với sản phẩm cược Keno Vietlott tương ứng với doanh thu được tính = 0% doanh thu)</b></br>
    4. Tất cả các cược hòa, cược vô hiệu, cược chéo 2 bên, cược hủy hoặc kiểu cược Malay có tỷ lệ dưới 0.5 (tương tự cho các kiểu cược khác như HK odd dưới 0.5; Indo odd dưới -2.00; US odd dưới -200; và Dec odd dưới 1.50), sẽ không được tính vào tổng số tiền đặt cược được yêu cầu.</br>
    5. Khi đang tham gia gói khuyến mãi này, bạn không được nhận khuyến mãi Hoàn trả tri ân cho đến khi hoàn thành vòng cược.Thời gian hoàn thành vòng cược để hưởng tiền thưởng là 30 ngày, quá thời gian trên, tiền gốc, tiền thưởng và tiền thắng cược của bạn sẽ bị hủy.</br>
    6. <span class="strong">PS-18NEW</span> nghiêm cấm hành vi gian lận, tạo nhiều tài khoản để trục lợi khuyến mãi, hoặc liên kết thông đồng của một nhóm trong quá trình đặt cược. Chúng tôi có Bộ phận Giám sát Kèo cược chuyên nghiệp hàng đầu. Một khi phát hiện gian lận, tài khoản sẽ bị khóa và thu hồi tất cả số tiền trong tài khoản.</br>    
    `
  },
  {
    id: 'hoantra-15',
    packageId: PLAN_TYPE_SUKIEN_HOANTRA15,
    banner: '/assets/images/common/khuyen-mai/hoantra1.5.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/hoantra1.5_MB.webp',
    title: 'Hoàn trả 1.25% tổng tiền cược ',
    description:
      'Tham gia cá cược tại <span class="strong">PS-18NEW</span>, người chơi có cơ hội nhận thưởng vô hạn cực cao lên đến 1.25%.',
    isNew: false,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/hoan-tra-1-25-tong-tien-cuoc',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/khuyen-mai/popup/hoantra1.25.webp',
      enpointBannerMoible:
        '/assets/images/common/khuyen-mai/popup/hoantra1.25_MB.webp',
      endpointTitleMobile: 'hoàn trả 1.25%'
    },
    contentDetails: `<ul>
    <li>1. Chương trình khuyến mãi áp dụng với tất cả thành viên mới thực hiện gửi tiền lần đầu tại <span class="strong">PS-18NEW</span>.</br></li>
    <li>2. Số tiền thưởng tối đa bạn có thể nhận lên đến 18,000,000 VND.</br></li>
    <li>3. Trước khi rút tiền, tổng tiền gửi và tiền thưởng cần trải qua 22 vòng cược.</br>Ví dụ: Bạn nạp lần đầu số tiền 1,000,000 VND, bạn sẽ nhận thưởng 118% tương đương 1,180,000 VND. Tổng số tiền này cần trải qua 22 vòng cược, tương ứng tổng tiền cược của bạn sẽ là: (1,000.000 + 1,180,000) x 22 = 47,960,000 VND.</br></li>
    <li>4. Khuyến mãi 118% áp dụng theo doanh thu cược, được tính như sau:</br>Thể thao; Thể thao ảo doanh thu được tính = 100% doanh thu cược.</br>Quay số doanh thu được tính = 40% doanh thu cược.</br>Keno; Number Game và Nổ hũ ( Slots Techplay) doanh thu được tính = 25% doanh thu cược</br><b>(Không áp dụng sản phẩm cược Keno Vietllot tương ứng với doanh thu được tính = 0% doanh thu cược)</b></br></li>
    <li>5. Tất cả các cược hòa, cược vô hiệu, cược chéo 2 bên, cược hủy hoặc kiểu cược Malay có tỷ lệ dưới 0.5 (tương tự cho các kiểu cược khác như HK odd dưới 0.5; Indo odd dưới -2.00; US odd dưới -200; và Dec odd dưới 1.50), sẽ không được tính vào tổng số tiền doanh thu được yêu cầu.</br></li><li>6. Khi đang tham gia gói khuyến mãi này, bạn không được nhận khuyến mãi Hoàn trả tri ân cho đến khi hoàn thành vòng cược.Thời gian hoàn thành vòng cược để hưởng tiền thưởng là 30 ngày, quá thời gian trên, tiền gốc, tiền thưởng và tiền thắng cược của bạn sẽ bị hủy.</br></li>
    <li>7. <span class="strong">PS-18NEW</span> nghiêm cấm hành vi gian lận, tạo nhiều tài khoản để trục lợi khuyến mãi, hoặc liên kết thông đồng của một nhóm trong quá trình đặt cược. Chúng tôi có Bộ phận Giám sát Kèo cược chuyên nghiệp hàng đầu. Một khi phát hiện gian lận, tài khoản sẽ bị khóa và thu hồi tất cả số tiền trong tài khoản.</br></br></li>
    </ul>`
  },
  {
    id: 'thuong-118',
    packageId: PLAN_TYPE_SUKIEN_THUONG118,
    banner: '/assets/images/common/khuyen-mai/thuong118.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/thuong118_MB.webp',
    title: 'Thưởng 118% cho lần nạp đầu',
    description:
      'Đăng ký tài khoản thành viên và nạp tiền lần đầu, khi lập lệnh nạp tiền trên <span class="strong">PS-18NEW</span> bạn click chọn "Thưởng 118%", sau đó click "Đồng Ý".',
    isNew: false,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/thuong-118-cho-lan-nap-dau',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/thuong-118.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/thuong-118-mb.webp',
      endpointTitleMobile: 'Thưởng 118%'
    },
    contentDetails: `
    1. Chương trình khuyến mãi áp dụng với tất cả thành viên mới thực hiện gửi tiền lần đầu tại <span class="strong">PS-18NEW</span>.</br>
    2. Số tiền thưởng tối đa bạn có thể nhận lên đến 18,000,000 VND.</br>
    3. Trước khi rút tiền, tổng tiền gửi và tiền thưởng cần trải qua 22 vòng cược.</br>Ví dụ: Bạn nạp lần đầu số tiền 1,000,000 VND, bạn sẽ nhận thưởng 118% tương đương 1,180,000 VND. Tổng số tiền này cần trải qua 22 vòng cược, tương ứng tổng tiền cược của bạn sẽ là: (1,000.000 + 1,180,000) x 22 = 47,960,000 VND.</br>
    4. Khuyến mãi 118% áp dụng theo doanh thu cược, được tính như sau:</br>Thể thao; Thể thao ảo doanh thu được tính = 100% doanh thu cược.</br>Quay số doanh thu được tính = 40% doanh thu cược.</br>Keno; Number Game và Nổ hũ ( Slots Techplay) doanh thu được tính = 25% doanh thu cược</br><b>(Không áp dụng sản phẩm cược Keno Vietllot tương ứng với doanh thu được tính = 0% doanh thu cược)</b></br>
    5. Tất cả các cược hòa, cược vô hiệu, cược chéo 2 bên, cược hủy hoặc kiểu cược Malay có tỷ lệ dưới 0.5 (tương tự cho các kiểu cược khác như HK odd dưới 0.5; Indo odd dưới -2.00; US odd dưới -200; và Dec odd dưới 1.50), sẽ không được tính vào tổng số tiền doanh thu được yêu cầu.</br>6. Khi đang tham gia gói khuyến mãi này, bạn không được nhận khuyến mãi Hoàn trả tri ân cho đến khi hoàn thành vòng cược.Thời gian hoàn thành vòng cược để hưởng tiền thưởng là 30 ngày, quá thời gian trên, tiền gốc, tiền thưởng và tiền thắng cược của bạn sẽ bị hủy.</br>
    7. <span class="strong">PS-18NEW</span> nghiêm cấm hành vi gian lận, tạo nhiều tài khoản để trục lợi khuyến mãi, hoặc liên kết thông đồng của một nhóm trong quá trình đặt cược. Chúng tôi có Bộ phận Giám sát Kèo cược chuyên nghiệp hàng đầu. Một khi phát hiện gian lận, tài khoản sẽ bị khóa và thu hồi tất cả số tiền trong tài khoản.</br></br>
    `
  },
  {
    id: 'thuong-150',
    packageId: PLAN_TYPE_SUKIEN_THUONG150,
    banner: '/assets/images/common/khuyen-mai/thuong150.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/thuong150_MB.webp',
    title: 'Thưởng 150% cho lần nạp đầu',
    description:
      'Đăng ký tài khoản thành viên và nạp tiền lần đầu, khi lập lệnh nạp tiền trên <span class="strong">PS-18NEW</span> bạn click chọn "Thưởng 150%", sau đó click "Đồng Ý".',
    isNew: false,
    isHot: false,
    isEnded: false,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/thuong-150-cho-lan-nap-dau',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/promotion-list/landing-page/thuong-150.webp',
      enpointBannerMoible:
        '/assets/images/common/promotion-list/landing-page/thuong-150-mb.webp',
      endpointTitleMobile: 'Thưởng 150%'
    },
    contentDetails: `
    1. Dành cho khách hàng nạp tiền lần đầu</br>
    2. Số tiền thưởng tối đa có thể nhận lên đến 5,800,000 VND</br>
    3. Tổng tiền gửi & tiền thưởng cần trải qua 28 vòng cược<br>Ví dụ: Bạn nạp lần đầu số tiền 1,000,000 VND, bạn sẽ nhận thưởng 150% tương đương 1,500,000 VND. Tổng số tiền này cần trải qua 28 vòng cược, tương ứng tổng tiền cược của bạn sẽ là: (1,000,000 + 1,500,000) x 28 = 70,000,000 VND.</br>
    4. Khuyến mãi 150% áp dụng theo doanh thu cược, được tính như sau:<br>Thể thao; Thể thao ảo doanh thu được tính = 100% doanh thu cược.</br>Quay số doanh thu được tính = 40% doanh thu cược. <br>Keno; Number Game và Nổ hũ (Slots Techplay) doanh thu được tính = 25% doanh thu cược. <br><b>(không áp dụng với sản phẩm cược Keno Vietlott tương ứng với doanh thu được tính = 0% doanh thu cược)</b></br>
    5. Tất cả các cược hòa, cược vô hiệu, cược chéo 2 bên, cược hủy hoặc kiểu cược Malay có tỷ lệ dưới 0.5 (tương tự cho các kiểu cược khác như HK odd dưới 0.5; Indo odd dưới -2.00; US odd dưới -200; và Dec odd dưới 1.50), sẽ không được tính vào tổng số tiền doanh thu được yêu cầu.</br>
    6. Khi đang tham gia gói khuyến mãi này, bạn không được nhận khuyến mãi Hoàn trả tri ân cho đến khi hoàn thành vòng cược.Thời gian hoàn thành vòng cược để hưởng tiền thưởng là 30 ngày, quá thời gian trên, tiền gốc, tiền thưởng và tiền thắng cược của bạn sẽ bị hủy.</br>
    7. <span class="strong">PS-18NEW</span> nghiêm cấm hành vi gian lận, tạo nhiều tài khoản để trục lợi khuyến mãi, hoặc liên kết thông đồng của một nhóm trong quá trình đặt cược. Chúng tôi có Bộ phận Giám sát Kèo cược chuyên nghiệp hàng đầu. Một khi phát hiện gian lận, tài khoản sẽ bị khóa và thu hồi tất cả số tiền trong tài khoản.</br> 
    `
  },

  {
    id: 'event-nohu',
    banner: '/assets/images/common/khuyen-mai/sukiennohu.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/sukiennohu_MB.webp',
    title: 'tặng 200 vòng quay miễn phí',
    description:
      'Chương trình Nạp Tiền Liền Tay Săn Ngay Hủ Lớn nhận lượt săn hũ miễn phí mỗi ngày bằng cách nạp tiền và tham gia cá cược tại nhà cái <span class="strong">PS-18NEW</span>.',
    isNew: true,
    isHot: false,
    isEnded: true,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/tang-200-vong-quay-mien-phi',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/khuyen-mai/popup/sukiennohu.webp',
      enpointBannerMoible:
        '/assets/images/common/khuyen-mai/popup/sukiennohu_MB.webp'
    }
  },
  {
    id: 'event-11thang11',
    banner: '/assets/images/common/khuyen-mai/sukien11.11.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/sukien11.11_MB.webp',
    title: '11.11 Săn Thưởng Lên Tới 1.110.000',
    description:
      'Sự kiện 11.11 Săn Thưởng Lên Tới 1.110.000 áp dụng cho khách hàng tham gia cược Thể thao, Live Casino, Game Bài và Quay Số, tham gia ngay tại <span class="strong">PS-18NEW</span>.',
    isNew: true,
    isHot: false,
    isEnded: true,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/san-thuong-11-11',
      isRequiredLogin: false,
      popupBanner: '/assets/images/common/khuyen-mai/popup/sukien11.11.webp',
      enpointBannerMoible:
        '/assets/images/common/khuyen-mai/popup/sukien11.11_MB.webp'
    }
  },
  {
    id: 'event-halloween',
    banner: '/assets/images/common/khuyen-mai/sukienhalloween.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/sukienhalloween_MB.webp',
    title: 'vui halloween thắng lớn triệu quà với slots game',
    description:
      'Vui Halloween. Thắng lớn triệu quà với Slots Game, thắng đã lớn, lại có quà hay. Việc gì không chơi.',
    isNew: false,
    isHot: false,
    isEnded: true,
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/vui-halloween-thang-lon-trieu-qua-voi-slots-game',
      isRequiredLogin: false,
      popupBanner:
        '/assets/images/common/khuyen-mai/popup/sukienhalloween.webp',
      enpointBannerMoible:
        '/assets/images/common/khuyen-mai/popup/sukienhalloween_MB.webp'
    }
  },
  {
    id: 'event-euro',
    banner: '/assets/images/common/khuyen-mai/rucluaeuro.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/rucluaeuro_MB.webp',
    title: 'rực lửa euro tổng giải thưởng 1 tỉ',
    description:
      'Chương trình Rực Lửa EURO đã kết thúc, vui lòng tham gia chương trình khuyến mãi khác của <span class="strong">PS-18NEW</span>, để nhận được nhiều ưu đãi của chúng tôi.',
    isNew: false,
    isHot: false,
    isEnded: true,
    action: {
      label: 'Xem Chi Tiết',
      type: TYPE_NAVIGATE_LINK,
      endpoint: '/khuyen-mai/euro',
      isRequiredLogin: false
    }
  },
  {
    id: 'event-lucky-raw',
    banner: '/assets/images/common/khuyen-mai/luckydraw.webp',
    bannerMobile: '/assets/images/common/khuyen-mai/luckydraw_MB.webp',
    title: 'lucky DRAW GIẢI THƯỞNG 100 TRIỆU ',
    description:
      'Chương trình Lucky Draw đã kết thúc, vui lòng tham gia chương trình khuyến mãi khác của <span class="strong">PS-18NEW</span>, để nhận được nhiều ưu đãi của chúng tôi.',
    isNew: false,
    isHot: false,
    isEnded: true,
    action: {
      label: 'Tham Gia Ngay',
      type: TYPE_NAVIGATE_LINK,
      endpoint: '/khuyen-mai/',
      isRequiredLogin: false
    }
  },
  {
    id: 'freespin-v2',
    banner: '',
    bannerMobile: '',
    title: 'Tặng 200 vòng quay miễn phí',
    description:
      'Nhận lượt săn hũ miễn phí mỗi ngày bằng cách nạp tiền và tham gia cá cược tại nhà cái PS-18NEW',
    isNew: false,
    isHot: false,
    isEnded: true,
    type: 'event',
    action: {
      label: 'Xem Chi Tiết',
      endpoint: '/khuyen-mai/tang-200-vong-quay-mien-phi',
      isRequiredLogin: false,
      popupBanner:
        '/assets/images/common/promotion-list/landing-page/vong-quay.webp',
      enpointBannerMoible: ''
    }
  }
]
