<template>
  <div class="jackpot-home" @scroll="onScroll">
    <div class="jackpot-container container">
      <div class="jackpot-header">
        <base-img src="assets/images/components/desktop/pages/home/jackpot/header-jackpot.png" class="god-of-wealth" />
        <div class="jackpot-info">
          <div class="jackpot-title">
            <div class="jackpot-title-link">
              <h1>JACKPOT</h1>
              <div class="link">
                <nuxt-link :to="LOBBY_GAME_URLS.NO_HU">
                  <span>{{ 'Vào Sảnh' }}</span>
                  <i class="icon-arrow-next light" />
                  <i class="icon-arrow-next" />
                </nuxt-link>
              </div>
            </div>
            <p>Hàng trăm máy quay slots từ cổ điển đến hiện đại</p>
          </div>
          <div class="jackpot-amount">
            <JackpotNumber v-if="jackpotNumber" :number="jackpotNumber?.toString()" />
          </div>
        </div>
      </div>
      <div class="slot-machine-container">
        <div class="jackpot-swiper-button-next" />
        <Swiper v-bind="swiperOption" class="swiper">
          <SwiperSlide v-for="(item, index) in listGame" :key="index">
            <SlotGameItem :item="item" :class="`${item?.class ? item?.class : ''}`" />
          </SwiperSlide>
        </Swiper>
        <div class="jackpot-swiper-button-prev" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { storeToRefs } from 'pinia'
import JackpotNumber from './jackpot-number.vue'
import BaseImg from '~/components/common/base-img.vue'
import SlotGameItem from '~/components/desktop/pages/home/jackpot/slot-game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'
import { LOBBY_GAME_URLS } from '~/config/page-url'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)
const jackpotNumber = ref(null)

const onScroll = () => {
  const scrollY = window.scrollY
  const isVisibleJackpotNumber = scrollY > 700 && scrollY < 1800

  if (isVisibleJackpotNumber) {
    jackpotNumber.value = sumJackpot.value.jackpotNohu
  } else {
    jackpotNumber.value = null
  }
}

const { onJackpotInterval, onJackpotIntervalClear, onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()

const { listGame, fetchListGameJackpot } = useGame()

onMounted(async () => {
  await fetchListGameJackpot()
  onJackpotInterval()
  onSumJackpotInterval()
  window.addEventListener('scroll', onScroll)
  // TODO: Removed the following line because it is not used
  jackpotNumber.value = sumJackpot.value.jackpotNohu
})

onBeforeUnmount(() => {
  onJackpotIntervalClear()
  onSumJackpotIntervalClear()
  window.removeEventListener('scroll', onScroll)
})

const swiperOption = reactive({
  slidesPerView: 6,
  slidesPerGroup: 6,
  spaceBetween: 24,
  centeredSlides: false,
  initialSlide: 2,
  navigation: {
    nextEl: '.jackpot-swiper-button-next',
    prevEl: '.jackpot-swiper-button-prev'
  },
  modules: [Navigation]
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/jackpot/jackpot-home.scss"></style>
