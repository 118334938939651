<template>
  <div class="promotion">
    <div v-if="promotionsFormat.length" class="promotion__content">
      <Swiper ref="swiperPromotion" v-bind="swiperOption" class="swiper promotion-swiper">
        <SwiperSlide v-for="item in promotionsFormat" :key="item.packageId">
          <PromotionItem :key="item.packageId" :item="item" :go-to-promotion="goToPromotion" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow } from 'swiper'
import usePromotionHome from '~/composables/home/usePromotionHome'
import PromotionItem from '@/components/desktop/pages/home/promotion-home/promotion-item'

const { fetchPromotionList, goToPromotion, promotionsFormat } = usePromotionHome()
const swiperOption = reactive({
  slidesPerView: '3',
  slidesPerGroup: 3,
  spaceBetween: 16,
  observer: true,
  observeParents: true,
  autoplay: false,
  navigation: {
    nextEl: '.promotion-swiper-button-next',
    prevEl: '.promotion-swiper-button-prev'
  },
  modules: [Autoplay, Pagination, EffectCoverflow]
})

onMounted(() => {
  fetchPromotionList()
})
</script>

<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/promotion-home/promotion.scss';
</style>
