interface LiveCasino {
  image: string
  link: string
  alt: string
}

export const LIVE_CASINOES: LiveCasino[] = [
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/b52.webp',
    link: '/livecasino/all?partner=b52',
    alt: 'b52'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/go88.webp',
    link: '/livecasino/all?partner=go',
    alt: 'mg-live'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/vivo.webp',
    link: '/livecasino/all?partner=vivo',
    alt: 'vivo'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/rik.webp',
    link: '/livecasino/all?partner=rik',
    alt: 'rik'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/evolution.webp',
    link: '/livecasino/all?partner=evo',
    alt: 'evolution'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/pragmatic.webp',
    link: '/livecasino/all?partner=pragmatic',
    alt: 'pragmatic'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/ezugi.webp',
    link: '/livecasino/all?partner=ezugi',
    alt: 'ezugi'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/hogaming.webp',
    link: '/livecasino/all?partner=hogaming',
    alt: 'hogaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/microgaming.webp',
    link: '/livecasino/all?partner=microgaming',
    alt: 'microgaming'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/mglive.webp',
    link: '/livecasino/all?partner=mg',
    alt: 'mglive'
  }
]
