import { PAGE } from '~/constants/router'
import { TYPE_METHOD } from '~/constants/common'
import { PATH_IMAGES, PATH_PROMOTION_URL, PATH_GAME_URL, PATH_GAME_MB_URL } from '~/constants/path'

export const TEXT_CONTENT = {
  GAME_CATEGORY: 'Thể loại',
  GAME_SUGGEST: 'Đề xuất',
  GAME_FAVORITE: 'Yêu thích',
  VIEW_ALL: 'Xem tất cả',
  PLAY_NOW: 'Chơi ngay',
  RANDOM: 'Trò Chơi Ngẫu nhiên'
}

export const IMAGE_CONTENT = {
  COIN: 'assets/images/components/desktop/pages/home/game/icon-coin.svg',
  ICON_GAME_TITLE: `${PATH_GAME_URL}icon-game-title.svg`
}

export const GAME_PAGE_URL = {
  home: `${PAGE.LOBBY}/${TYPE_METHOD.ALL}`,
  ingame: `${PAGE.LOBBY}/${TYPE_METHOD.IN_GAME}`,
  fishing: `${PAGE.LOBBY}/${TYPE_METHOD.FISHING}`,
  quay_so: `${PAGE.LOBBY}/${TYPE_METHOD.QUAYSO}`,
  nohu: `${PAGE.LOBBY}/${TYPE_METHOD.JACKPOT}`,
  slots: `${PAGE.LOBBY}/${TYPE_METHOD.SLOTS}`,
  toc_chien: `${PAGE.LOBBY}/${TYPE_METHOD.QUICK_GAME}`,
  card_game: `${PAGE.LOBBY}/${TYPE_METHOD.GAME_BAI}`,
  lotte: `${PAGE.LOBBY}/${TYPE_METHOD.LODE}`,
  game_bai: `${PAGE.LOBBY}/${TYPE_METHOD.GAME_BAI}`,
  tables: `${PAGE.LOBBY}/${TYPE_METHOD.TABLES}`,
  quick_game: `${PAGE.LOBBY}/${TYPE_METHOD.QUICK_GAME}`,
  live_casino: `${PAGE.LOBBY}/${TYPE_METHOD.EVO}`,
  live_casino_evo: `${PAGE.CASINO}/${TYPE_METHOD.LIVE_CASINO}`,
  live_casino_vivo: `${PAGE.CASINO}?type=${TYPE_METHOD.VIVO}`,
  live_casino_ebet: `${PAGE.CASINO}?type=${TYPE_METHOD.EBET}`,
  live_casino_ezugi: `${PAGE.CASINO}?type=${TYPE_METHOD.EZUGI}`,
  live_casino_pragmatic: `${PAGE.CASINO}?type=${TYPE_METHOD.PRAGMATIC}`,
  live_casino_n2_live: `${PAGE.CASINO}?type=${TYPE_METHOD.N2_LIVE}`,
  live_casino_microgaming: `${PAGE.CASINO}?type=${TYPE_METHOD.MICROGAMING}`,
  live_casino_hogaming: `${PAGE.CASINO}?type=${TYPE_METHOD.HOGAMING}`,
  // new casino /api/v1/casino/search?limit=36&keyword=&partner_game_type=baccarat&partner=evo
  all: `${PAGE.CASINO}?type=all&partner=all`,
  livestream: `${PAGE.CASINO}?type=livestream&partner=all`,
  baccarat: `${PAGE.CASINO}?type=baccarat&partner=all`,
  blackjack: `${PAGE.CASINO}?type=blackjack&partner=all`,
  sicbo: `${PAGE.CASINO}?type=sicbo&partner=all`,
  roulette: `${PAGE.CASINO}?type=roulette&partner=all`,
  lottery: `${PAGE.CASINO}?type=lottery&partner=all`,
  poker: `${PAGE.CASINO}?type=poker&partner=all`,
  dragontiger: `${PAGE.CASINO}?type=dragontiger&partner=all`,
  bullbull: `${PAGE.CASINO}?type=bullbull&partner=all`
}

export const EMPTY_CONTENT = [
  {
    icon: `${PATH_IMAGES}icon-empty.webp`,
    text: 'Đang cập nhật dữ liệu'
  }
]

export const PACKAGE_ID_BLOCK_GAME = [2, 3, 4, 5, 6, 7]

export const DISPLAY_TYPE = {
  all: 25,
  tables: 2,
  slots: 3,
  jackpot: 24,
  in_game: 5,
  lottery: 7,
  lo_de: 8,
  fishing: 9,
  instance_game: 14,
  game_bai: 15,
  othergame: 19
}

export const TITLE_GAME = {
  all: 'Chợ Games',
  tables: 'Table Games',
  slots: 'Slots',
  jackpot: 'Nổ Hũ',
  in_game: 'INGAME',
  lottery: 'Quay số',
  lo_de: 'Lô Đề',
  fishing: 'Bắn Cá',
  instance_game: 'Games Nhanh',
  game_bai: 'Games Bài',
  othergame: 'Games Khác'
}

export const CATEGORY_NCC = {
  all: {
    nameMB: 'NCC',
    name: 'Tất cả',
    icon: `${PATH_GAME_URL}icon-all-mb.webp`,
    iconMB: `${PATH_GAME_URL}icon-all-mb.webp`,
    type: 'all',
    isShow: true
  },
  vingame: {
    nameMB: 'Techplay',
    name: 'Techplay',
    icon: `${PATH_GAME_URL}icon-techplay.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-techplay.webp`,
    type: 'vingame',
    isShow: false
  },
  pragmatic: {
    nameMB: 'Pragmatic',
    name: 'Pragmatic',
    icon: `${PATH_GAME_URL}icon-pragmatic.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-pragmatic.webp`,
    type: 'pragmatic',
    isShow: false
  },
  playngo: {
    nameMB: 'PLAY’nGO',
    name: 'PLAY’nGO',
    icon: `${PATH_GAME_URL}icon-play-go.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-play-go.webp`,
    type: 'playngo',
    isShow: false
  },
  onegame: {
    nameMB: 'Onegame',
    name: 'Onegame',
    icon: `${PATH_GAME_URL}icon-onegame.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-onegame.webp`,
    type: 'onegame',
    isShow: false
  },
  spinomenal: {
    nameMB: 'Spinomenal',
    name: 'Spinomenal',
    icon: `${PATH_GAME_URL}icon-spinomenal.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-spinomenal.webp`,
    type: 'spinomenal',
    isShow: false
  },
  jdb: {
    nameMB: 'JDB',
    name: 'JDB',
    icon: `${PATH_GAME_URL}icon-jdp.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-jdp.webp`,
    type: 'jdb',
    isShow: false
  },
  mg_slot: {
    nameMB: 'Micro Gaming',
    name: 'Micro Gaming',
    icon: `${PATH_GAME_URL}icon-micro.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-micro.webp`,
    type: 'mg_slot',
    isShow: false
  },
  cq9: {
    nameMB: 'CQ9',
    name: 'CQ9',
    icon: `${PATH_GAME_URL}icon-cq9.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-cq9.webp`,
    type: 'cq9',
    isShow: false
  },
  rik: {
    nameMB: 'Rickvip',
    name: 'Rickvip',
    icon: `${PATH_GAME_URL}icon-rik.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-rik.webp`,
    type: 'rik',
    isShow: false
  },
  b52: {
    nameMB: 'B52',
    name: 'B52',
    icon: `${PATH_GAME_URL}icon-b52.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-b52.webp`,
    type: 'b52',
    isShow: false
  },
  ktech: {
    nameMB: 'Ktech',
    name: 'Ktech',
    icon: `${PATH_GAME_URL}icon-ktech.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-ktech.webp`,
    type: 'ktech',
    isShow: false
  },
  athena: {
    nameMB: 'Athena',
    name: 'Athena',
    icon: `${PATH_GAME_URL}icon-athena.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-athena.webp`,
    type: 'athena',
    isShow: false
  },
  betsoftgaming: {
    nameMB: 'Betsoftgaming',
    name: 'Betsoftgaming',
    icon: `${PATH_GAME_URL}icon-betsoftgaming.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-betsoftgaming.webp`,
    type: 'betsoftgaming',
    isShow: false
  },
  tomhorm: {
    nameMB: 'Tomhorm',
    name: 'Tomhorm',
    icon: `${PATH_GAME_URL}icon-tomhorm.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-tomhorm.webp`,
    type: 'tomhorm',
    isShow: false
  },
  bbtech: {
    nameMB: 'Bbtech',
    name: 'Bbtech',
    icon: `${PATH_GAME_URL}icon-bbtech.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-bbtech.webp`,
    type: 'bbtech',
    isShow: false
  },
  spribe: {
    nameMB: 'Spribe',
    name: 'Spribe',
    icon: `${PATH_GAME_URL}icon-spribe.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-spribe.webp`,
    type: 'spribe',
    isShow: false
  },
  evoplay: {
    nameMB: 'Evoplay',
    name: 'Evoplay',
    icon: `${PATH_GAME_URL}icon-evoplay.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-evoplay.webp`,
    type: 'evoplay',
    isShow: false
  },
  qtech: {
    nameMB: 'Qtech',
    name: 'Qtech',
    icon: `${PATH_GAME_URL}icon-qtech.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-qtech.webp`,
    type: 'qtech',
    isShow: false
  },
  habanero: {
    nameMB: 'Habanero',
    name: 'Habanero',
    icon: `${PATH_GAME_URL}icon-habanero.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-habanero.webp`,
    type: 'habanero',
    isShow: false
  },
  go: {
    nameMB: 'Go88',
    name: 'Go88',
    icon: `${PATH_GAME_URL}icon-go.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-go.webp`,
    type: 'go',
    isShow: false
  },
  betradar: {
    nameMB: 'Betradar',
    name: 'Betradar',
    icon: `${PATH_GAME_URL}icon-betradar.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-betradar.webp`,
    type: 'Betradar',
    isShow: false
  },
  redtiger: {
    nameMB: 'Redtiger',
    name: 'Redtiger',
    icon: `${PATH_GAME_URL}icon-redtiger.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-redtiger.webp`,
    type: 'redtiger',
    isShow: false
  },
  netent: {
    nameMB: 'Netent',
    name: 'Netent',
    icon: `${PATH_GAME_URL}icon-netent.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-netent.webp`,
    type: 'netent',
    isShow: false
  },
  wazdan: {
    nameMB: 'Wazdan',
    name: 'Wazdan',
    icon: `${PATH_GAME_URL}icon-wazdan.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-wazdan.webp`,
    type: 'wazdan',
    isShow: false
  },
  pgsoft: {
    nameMB: 'Pgsoft',
    name: 'Pgsoft',
    icon: `${PATH_GAME_URL}icon-pgsoft.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-pgsoft.webp`,
    type: 'pgsoft',
    isShow: false
  },
  simpleplay: {
    nameMB: 'Simpleplay',
    name: 'Simpleplay',
    icon: `${PATH_GAME_URL}icon-simpleplay.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-simpleplay.webp`,
    type: 'simpleplay',
    isShow: false
  },
  pariplay: {
    nameMB: 'Pariplay',
    name: 'Pariplay',
    icon: `${PATH_GAME_URL}icon-pariplay.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-pariplay.webp`,
    type: 'pariplay',
    isShow: false
  },
  sagaming: {
    nameMB: 'Sagaming',
    name: 'Sagaming',
    icon: `${PATH_GAME_URL}icon-sagaming.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-sagaming.webp`,
    type: 'sagaming',
    isShow: false
  },
  iwin: {
    nameMB: 'Iwin',
    name: 'Iwin',
    icon: `${PATH_GAME_URL}icon-iwin.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-iwin.webp`,
    type: 'iwin',
    isShow: false
  },
  yeebet: {
    nameMB: 'Yeebet',
    name: 'Yeebet',
    icon: `${PATH_GAME_URL}icon-yeebet.webp`,
    iconMB: `${PATH_GAME_MB_URL}icon-yeebet.webp`,
    type: 'yeebet',
    isShow: false
  }
}

export const SORT = [
  {
    nameMB: 'Mặc Định',
    name: 'Mặc định',
    icon: `${PATH_GAME_URL}icon-sort-default.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-default-mb.webp`,
    type: 'default',
    typeUrl: '',
    isShow: true
  },
  {
    nameMB: 'Game Mới',
    name: 'Game mới',
    icon: `${PATH_GAME_URL}icon-sort-new.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-new-mb.webp`,
    type: 'NEW_GAME',
    typeUrl: 'game-moi',
    isShow: true
  },
  {
    nameMB: 'Nhiều Người Chơi',
    name: 'Nhiều người chơi',
    icon: `${PATH_GAME_URL}icon-sort-player-mb.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-player-mb.svg`,
    type: 'TOP_USER_BET',
    typeUrl: 'game-duoc-nhieu-nguoi-choi',
    isShow: true
  },
  {
    nameMB: 'Game Hấp Dẫn',
    name: 'Game Hấp Dẫn',
    icon: `${PATH_GAME_URL}icon-sort-hot.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-hot-mb.webp`,
    type: 'HOT_GAME',
    typeUrl: 'game-dang-hot',
    isShow: true
  },
  {
    nameMB: 'Người Mới',
    name: 'Người Mới',
    icon: `${PATH_GAME_URL}icon-sort-newber-mb.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-newber-mb.svg`,
    type: 'FOR_NEWBER',
    typeUrl: 'game-cho-nguoi-moi',
    isShow: true
  },
  {
    nameMB: 'Cược Nhiều',
    name: 'Cược nhiều',
    icon: `${PATH_GAME_URL}icon-sort-bets.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-bets-mb.webp`,
    type: 'TOP_BET',
    typeUrl: 'game-duoc-cuoc-nhieu',
    isShow: true
  }
]

export const PROMOTION_BANNER = {
  default: {
    image: PATH_PROMOTION_URL + 'first-deposit-sport-150.webp',
    image_mb: PATH_PROMOTION_URL + 'first-deposit-sport-150-mb.webp',
    url: PAGE.PROMOTION + '/first-deposit-sport-150'
  },
  casino: {
    image: PATH_PROMOTION_URL + 'refunds-casino-0.5.webp',
    image_mb: PATH_PROMOTION_URL + 'refunds-casino-0.5-mb.webp',
    url: PAGE.PROMOTION + '/refunds-casino-0.5'
  },
  lo_de: {
    image: PATH_PROMOTION_URL + 'refunds-lottery-0.3.webp',
    image_mb: PATH_PROMOTION_URL + 'refunds-lottery-0.3-mb.webp',
    url: PAGE.PROMOTION + '/refunds-lottery-0.3'
  },
  fishing: {
    image: PATH_PROMOTION_URL + 'refunds-fish-0.3.webp',
    image_mb: PATH_PROMOTION_URL + 'refunds-fish-0.3-mb.webp',
    url: PAGE.PROMOTION + '/refunds-fish-0.3'
  },
  slots: {
    image: PATH_PROMOTION_URL + 'refunds-slots-0.5.webp',
    image_mb: PATH_PROMOTION_URL + 'refunds-slots-0.5-mb.webp',
    url: PAGE.PROMOTION + '/refunds-slots-0.5'
  },
  lottery: {
    image: PATH_PROMOTION_URL + 'refunds-keno-0.5.webp',
    image_mb: PATH_PROMOTION_URL + 'refunds-keno-0.5-mb.webp',
    url: PAGE.PROMOTION + '/refunds-keno-0.5'
  }
}
