import { storeToRefs } from 'pinia'
import { usePromotionApi } from '~/api/promotion'
import { promotionItems } from '~/resources/khuyen-mai.js'
import { useAppStore } from '~/store/app'
import { MODAL } from '~/constants'
import useModal from '~/composables/useModal'
import { IPromotion, IPromotionItem } from '~/types/promotion'

export default () => {
  const { $axios, $pinia } = useNuxtApp()
  const router = useRouter()
  const promotionList = ref<IPromotion[]>([])
  const store = useAppStore($pinia)
  const { currentUser } = storeToRefs(store)
  const { LIST_PROMOTION } = usePromotionApi()
  const { openModalWithNavigate } = useModal()

  const fetchPromotionList = async () => {
    try {
      const { data: response } = await $axios.get(LIST_PROMOTION)
      if (response.status === 'OK') {
        promotionList.value = response.data.map((item: Record<string, any>) => setItem(item))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const promotionsFormat = computed(() =>
    promotionList.value.filter((promotion) => filteredPCIds.includes(promotion.id)).reverse()
  )

  const filteredPCIds = ['hoantra-15', 'return-158', 'intro-friend']
  const filteredIds = ['thuong-150', 'hoantra-15', 'athena88']

  const promotionsMBFormat = computed(() => {
    return promotionList.value.filter((promotion) => filteredIds.includes(promotion.id)).reverse()
  })

  const openAuthentication = (formName: string) => {
    openModalWithNavigate(MODAL.AUTHENTICATION, formName)
  }

  const handleNavigateLink = (item: IPromotionItem) => {
    if (item?.action?.isRequiredLogin && Object.keys(currentUser.value).length === 0) {
      openAuthentication(MODAL.LOGIN)
    } else {
      router.push({ path: item?.action?.endpoint })
    }
  }

  const goToPromotion = (item: IPromotionItem) => {
    if (Object.keys(item?.action).length === 0) {
      return
    }
    handleNavigateLink(item)
  }

  const setItem = (promotion: Partial<IPromotionItem>) => {
    const item = promotionItems.find((obj) => obj.id === promotion.id)
    if (item) {
      item.title = <string>promotion.title
      item.isNew = <boolean>promotion.new
      item.isHot = <boolean>promotion.hot
      item.isEnded = <boolean>promotion.end
      item.description = <string>promotion.description
      item.banner = <string>promotion.image
      item.bannerMobile = <string>promotion.image_mb
      return item
    } else {
      return {
        id: promotion.id,
        banner: promotion.image,
        bannerMobile: promotion.image_mb,
        title: promotion.title,
        description: promotion.description,
        isNew: promotion.new,
        isHot: promotion.hot,
        isEnded: promotion.end,
        action: {
          label: promotion.cta,
          type: 'navigate-link',
          endpoint: promotion.url,
          isRequiredLogin: false,
          typeMobile: 'navigate-link',
          endpointMobile: promotion.url_mb
        }
      }
    }
  }

  return {
    goToPromotion,
    promotionsFormat,
    promotionsMBFormat,
    fetchPromotionList
  }
}
