<template>
  <div class="live-casino">
    <HeaderSection
      title="LIVE CASINO"
      :url-section="`${PAGE.CASINO}/all`"
      text-url="Xem Tất Cả"
      :is-hide-light-arrow="false"
      class="container"
    />
    <div class="frame container">
      <div class="background-image" />
    </div>
    <div class="swiper-container container">
      <div class="swiper-button-prev" />
      <Swiper v-bind="swiperOption" class="swiper" @slide-change="handleSlideChange">
        <SwiperSlide v-for="(item, index) in liveCasinoList" :key="index">
          <nuxt-link :to="item.link" class="slider-item">
            <base-img :src="item.image" :alt="item.alt" />
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
      <div class="swiper-button-next" />
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { LIVE_CASINOES } from '@/resources/live-casino'
import BaseImg from '~/components/common/base-img.vue'
import HeaderSection from '~/components/common/header-section.vue'
import { PAGE } from '~/constants/router'

const liveCasinoList = reactive(LIVE_CASINOES)

const swiperOption = reactive({
  slidesPerView: 5,
  slidesPerGroup: 1,
  spaceBetween: 20,
  centeredSlides: true,
  initialSlide: 2,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  modules: [Navigation]
})

const handleSlideChange = (swiper) => {
  const slides = swiper.slides
  const activeIndex = swiper.activeIndex % slides.length
  slides.forEach((slide, index) => {
    slide.style.transform = 'scale(0.99)'
    if (index === activeIndex) {
      slide.style.transform = 'scale(1.289)'
    } else if (
      index === (activeIndex - 1 + slides.length) % slides.length ||
      index === (activeIndex + 1) % slides.length
    ) {
      slide.style.transform = 'scale(1.138)'
    } else {
      slide.style.transform = 'scale(0.99)'
    }
  })
}

onMounted(() => {
  const swiperInstance = document.querySelector('.swiper').swiper
  handleSlideChange(swiperInstance)
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/live-casino.scss"></style>
